import common from '@/common'
import { optionsFun, parseTime } from '@/utils'
// analysis 页面tabs
export const tabs = [
  {
    label: 'Rusting Issues',
    componentName: 'issues'
  },
  {
    label: 'Inspection Defects ',
    componentName: 'defects'
  },
  {
    label: 'Tilt Angle Issues',
    componentName: 'tilt'
  }
]

export const listFormView = [
  {
    label: 'Search',
    prop: 'defectId',
    width: '50px',
    type: 'input'
  },
  {
    label: 'Severity',
    prop: 'severity',
    width: '60px',
    type: 'select',
    options: common.constData.severity.map((item) => {
      const copyItem = { ...item }
      if (copyItem.dictLabel === 'Critical') {
        copyItem.dictLabel = 'Major'
        copyItem.dictValue = 'Major'
      }

      return copyItem
    })
  },
  {
    label: 'Type',
    prop: 'type',
    type: 'select',
    width: '40px',
    options: optionsFun({
      Corrosion: 'Corrosion',
      'Rusting Issue': 'Rust',
      Debonding: 'Debonding'
    })
  }
]

export const headers = [
  {
    label: 'Defect ID',
    prop: 'defectId',
    property: {
      sortable: 'custom',
      width: '200px'
    }
  },
  {
    label: 'Location',
    prop: 'location',
    property: {
      width: '200px'
    }
  },
  {
    label: 'Severity',
    prop: 'severity'
  }
]

// tilt-angle-issues 组件header
export const tiltHeaders = [
  {
    label: 'Time and Date Detected',
    prop: 'dataTimestamp',
    property: {
      sortable: 'custom',
      width: '200px'
    },

    formatter: (row) => {
      return `${parseTime(row.dataTimeStr, 'reg')} ${parseTime(row.dataTimeStr, '{h}:{i}')}`
    }
  },
  {
    label: 'Tilt Angle',
    prop: 'tilt',
    formatter: (row) =>
      `Rx ${Number(row.rx).toFixed(4)}°&emsp;
       Ry ${Number(row.ry).toFixed(4)}°&emsp;
       Rz ${Number(row.rz).toFixed(4)}°`
  },
  {
    label: 'Description',
    prop: 'overAngleDesc'
  }
]
